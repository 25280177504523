import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import { config } from "@fortawesome/fontawesome-svg-core";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(fas);
library.add(far);

// import { create } from "core-js/core/object";
import "./index.css";
import HomeTab from "./components/HomeTab.vue";
// import AboutTab from "./components/AboutTab.vue";
// import HowToJoinTab from "./components/HowToJoinTab.vue";
// import TeamsTab from "./components/TeamsTab.vue";
// import DonateTab from "./components/DonateTab.vue";
// import PhotosTab from "./components/PhotosTab.vue";
// import ContactTab from "./components/ContactTab.vue";
import MembersTab from "./components/MembersTab.vue";
import App from "./App.vue";

import * as Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    base: "process.env.BASE_URL",

    routes: [
        { path: "/", name: "Home", component: HomeTab, pathToRegexpOptions: { strict: false } },
        { path: "/members/", name: "Members", component: MembersTab },
    ],

    navigationFallback: {
        rewrite: "/index.html",
        exclude: ["/images/*.{png,jpg,gif}", "/css/*"],
    },
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                el: to.hash, // Scroll to the element with the corresponding ID
                behavior: "smooth", // Optional: for smooth scrolling
            };
        } else {
            return { top: 0 };
        }
    },
});

const app = createApp(App);
app.use(router, VueAxios, axios);
app.component("FontAwesomeIcon", FontAwesomeIcon);
app.mount("#app");
