<script>
import { Amplify } from "aws-amplify";
import amplifyconfig from "../amplifyconfiguration.json";
import { confirmUserAttribute, fetchUserAttributes, getCurrentUser, updateUserAttributes, deleteUser } from "aws-amplify/auth";

Amplify.configure(amplifyconfig);
import "@aws-amplify/ui-vue/styles.css";

import { ref } from "vue";

export default {
    name: "DeleteAccountModal",
    emits: ["closeEvent"],
    data() {
        return {
            updatedToggle: false,
            submitLoading: false,
            showError: false,
        };
    },
    props: {},
    setup(_, { emit }) {
        const handleClose = () => {
            emit("closeEvent"); // Emit the 'close' event
        };

        const deleteString = ref("");
        return { handleClose, deleteString };
    },

    methods: {
        deleteAccount() {
            this.submitLoading = true;

            if (this.deleteString.length <= 0 || this.deleteString !== "DELETE") {
                this.submitLoading = false;
                this.showError = true;
                return;
            } else {
                this.showError = false;
                // call async here
                this.deleteBSUser();
            }
        },
        async deleteBSUser() {
            try {
                await deleteUser();
                this.showError = false;
            } catch (error) {
                console.error("Error deleting user", error);
                this.showError;
            }
            this.submitLoading = false;
            this.updatedToggle = true; // modal can be closed now
        },
    },
};
</script>

<template>
    <div class="modal fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
        <div class="modal-content bg-white rounded-lg shadow-lg w-[90%] md:w-[70%]">
            <div class="flex justify-end py-1 px-2">
                <button class="" @click="handleClose"><font-awesome-icon :icon="['fas', 'xmark']" size="xl" /></button>
            </div>
            <div class="px-6 pb-3">
                <div v-if="updatedToggle == false">
                    <h4 class="pb-2 font-bold">Are you sure you want to delete your account?</h4>
                    <p>Deleting your account will remove all of your information from our database. This action cannot be undone. You will be redirected after this step is completed.</p>

                    <div class="pt-4">
                        <div v-if="!submitLoading">
                            <label for="verificationCode" class="italic text-gray-500">To confirm this, type "DELETE".</label>
                            <div class="flex space-x-2">
                                <input type="text" v-model="deleteString" required class="px-2 py-2 border w-2/3 outline-none rounded-md" placeholder="DELETE" />
                                <button type="submit" class="bg-red-500 text-white rounded-md p-2" :disabled="submitLoading" @click="deleteAccount">Delete Account</button>
                            </div>
                            <p class="text-red-500 text-bold" v-show="showError">You must type "DELETE" to proceed.</p>
                        </div>
                        <div class="mb-6 w-full px-6 py-2.5 font-medium uppercase leading-normal" v-if="submitLoading">
                            <div class="loader mx-auto"></div>
                        </div>
                    </div>
                </div>
                <div v-else>Your account has been deleted.</div>
            </div>
        </div>
    </div>
</template>

<style lang="css" scoped>
.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
}
.loader::before,
.loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid black;
    animation: prixClipFix 2s linear infinite;
}
.loader::after {
    transform: rotate3d(90, 90, 0, 180deg);
    border-color: rgb(15, 130, 233);
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    75%,
    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
}
</style>
