<template>
    <div class="app-container flex flex-col min-h-[100vh]">
        <!-- hero/navigation/header -->
        <NavBar />
        <div class="content bg-brand-light-shade grow">
            <!-- Home Page -->
            <router-view></router-view>
            <!-- footer -->
        </div>
        <FooterBar />
    </div>
    <button @click="scrollToTop" :class="[`z-50 hidden md:block fixed bg-brand-main-color text-white h-[40px] w-[40px] rounded-full bottom-[80px] right-[20px]`, showButton == true ? 'transform transition opacity-1 duration-900' : 'transform transition opacity-0 duration-900']" :style="{ opacity: showButton ? 1 : 0 }"><font-awesome-icon :icon="['fas', 'angles-up']" size="lg" /></button>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import FooterBar from "./components/FooterBar.vue";

export default {
    name: "App",
    components: {
        NavBar,
        FooterBar,
    },
    data() {
        return {
            showButton: false,
        };
    },

    mounted() {
        window.addEventListener("scroll", this.toggleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.toggleScroll);
    },
    methods: {
        toggleScroll() {
            this.showButton = window.scrollY > 200; // Show button after scrolling 200px
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth", // Add smooth scrolling behavior
            });
        },
    },
};
</script>
