<script>
export default {
    name: "VerticalImagesWithTabs",
    data() {
        return {
            currentTab: 0,
            slides: [
                { imageUrl: require("../../public/photos/verticaltabsphotos/blueshadowsremountspic.jpg"), title: "Beginner Remount Class", content: "A member begins as a remount where they first learn the basics about horse care and riding. Remounts learn basic drill commands and the teamwork needed to create patterns." },
                { imageUrl: require("../../public/photos/verticaltabsphotos/blueshadowsintermediatetroop.jpg"), title: "Intermediate Riding Troop", content: "Apon completition of the remount class, members are promoted to the rank of Trooper. Through gained skills and dedication, members may then be awarded promotions which are both an honor and come with increased responsibilities for both themselves and assisting their teammates." },
                { imageUrl: require("../../public/photos/verticaltabsphotos/blueshadowsJAT.jpg"), title: "Advanced Drill Team", content: "The advanced teams are comprised of the best riders in Blue Shadows, and participate in advanced competitions across the Western United States. These teams involve a high level of dedication to Blue Shadows and a commitment to perform the most intricate drills possible at a fast pace." },
            ],
        };
    },
};
</script>

<template>
    <div class="lg:pt-[50px] grid grid-cols-1 lg:grid-cols-2 lg:gap-x-10">
        <div class="col-span-1 flex flex-col justify-center place-items-center">
            <img :src="slides[this.currentTab].imageUrl" class="h-[225px] lg:h-full lg:object-cover my-5 lg:my-[unset]" />
        </div>
        <div class="flex flex-col space-y-4">
            <div v-for="(slide, index) in slides" :key="index">
                <div @click="this.currentTab = index" class="cursor-pointer">
                    <div :class="this.currentTab == index ? 'border-solid border-s-4 border-brand-dark-accent' : 'border-s-4 border-solid border-brand-light-accent border-opacity-[0.15]'">
                        <div class="content pl-4">
                            <h3 class="font-bold">{{ slide.title }}</h3>
                            <p>{{ slide.content }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
