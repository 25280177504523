<script>
// import NavBar from "./NavBar.vue";

// import TestimonialCards from "./TestimonialCards.vue";
import VerticalImagesWithTabs from "./VerticalImagesWithTabs.vue";
import ContactTab from "./ContactTab.vue";
import PhotosTab from "./PhotosTab.vue";
import FaqSection from "./FaqSection.vue";

export default {
    name: "HomeTab",
    components: {
        // TestimonialCards,
        VerticalImagesWithTabs,
        ContactTab,
        PhotosTab,
        FaqSection,
    },
    methods: {
        goToSection(id) {
            this.toggleMenu = false;
            const section = document.getElementById(id);
            if (section) {
                section.scrollIntoView({ behavior: "smooth" });
            }
        },
    },
};
</script>

<template>
    <!-- hero cta -->
    <div class="relative flex items-center hero-cta h-[600px]" id="home-section">
        <!-- Background Image Layer with Opacity -->
        <div class="absolute inset-0 bg-center bg-cover bg-no-repeat" style="background-image: url('/photos/reno-chevron-2015-cropped.jpeg'); box-shadow: rgba(5 5 7 / 40%) 0px 0px 0px 1000px inset"></div>

        <!-- Content Layer -->
        <!-- <div class="rounded-full" style="box-shadow: #050507a3 0px 0px 0px 1000px inset"> -->
        <div class="relative z-10 w-full h-full flex items-center justify-center">
            <!-- Inner Content -->
            <div class="container pt-[50px] pb-[50px] lg:pt-[100px] lg:pb-[100px]">
                <div class="mx-auto">
                    <div class="text-white">
                        <h1 class="text-center font-bold">Experience the Sport of Equestrian Drill With Leadership Through Guidance</h1>
                        <h5 class="text-center font-bold py-2">Blue Shadows Mounted Drill Team is a non-profit equestrian organization dedicated to teaching young people how to ride a horse while learning teamwork skills and responsibility.</h5>
                    </div>
                    <div class="cta-group flex py-4 space-x-4 justify-center">
                        <button class="bg-brand-blue-400 text-brand-light-shade p-4 rounded-md max-md:flex max-md:flex-col max-md:text-center max-md:items-center" @click="goToSection('contact-section')">
                            <font-awesome-icon :icon="['far', 'envelope']" size="lg" class="text-brand-light-shade" />
                            <span class="md:pl-2 text-brand-light-shade">Contact Us</span>
                        </button>
                        <button class="bg-brand-light-accent text-brand-light-shade p-4 rounded-md" @click="goToSection('about-section')">
                            <span class="text-brand-light-shade order-2 md:order-1">Learn More</span>
                            <font-awesome-icon :icon="['fas', 'arrow-right']" class="text-brand-light-shade pl-1 md:pl-2 order-1 md:order-2" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- </div> -->
    </div>

    <!-- image grid -->
    <section class="bg-brand-light-shade">
        <div class="container pt-[50px] pb-[50px] lg:pt-[100px] lg:pb-[100px]">
            <PhotosTab />
        </div>
    </section>

    <!-- 3 cards more info -->
    <section class="bg-brand-dark-shade" id="about-section">
        <div class="container pt-[50px] pb-[50px] lg:pt-[100px] lg:pb-[100px]">
            <div class="bs-cards text-center w-full lg:max-w-[70%] mx-auto">
                <h2 class="font-bold pb-4 text-white">Blue Shadows is currently located in Lake View Terrace, in Southern California.</h2>
                <p class="text-white">Our facility also has horses available, so horse ownership is not necessary. In fact, most members do not own their own horses. Blue Shadows members participate in weekly drilling practice, horse shows, parades, trail rides and community events. In addition, our advanced teams perform in outside equestrian team competitions. The instructors, mentors and board members are all volunteers and funding for events, operating costs, member support and scholarship program comes from donations, fundraisers and sponsorships.</p>
            </div>
            <div class="grid lg:grid-cols-3 mt-8 gap-4">
                <div class="shadow-2xl rounded-lg mt-7 mx-auto w-full min-h-[300px] bg-brand-light-shade relative pt-[65%]">
                    <div class="hidden lg:block absolute top-[-30px] bg-brand-main-color w-[85%] h-[50%] p-4 rounded-md left-1/2 transform -translate-x-1/2 shadow-xl">
                        <img class="absolute top-0 p-4 left-0 w-full rounded-md object-cover" src="../../public/photos/rose-parade.jpg" />
                    </div>
                    <img class="lg:hidden absolute top-0 p-4 left-0 w-full rounded-md object-cover" src="../../public/photos/rose-parade.jpg" />
                    <div class="relative p-4 text-center">
                        <h4 class="text-brand-dark-shade font-bold">2025 Pasadena Tournament of Roses</h4>
                        <p class="card-description text-dark-secondary text-[rgb(26,46,102)]">In 2016, a parade team was formed with the dream of one day participating in the Rose Parade, a dream that came true in 2019 when they made their debut on Colorado Blvd. The Blue Shadows Mounted Drill Team proudly rides in their sixth appearance this year in 2025!</p>
                    </div>
                </div>

                <div class="shadow-2xl rounded-lg mt-7 mx-auto w-full min-h-[300px] bg-brand-light-shade relative pt-[65%]">
                    <div class="hidden lg:block absolute top-[-30px] bg-brand-main-color w-[85%] h-[50%] p-4 rounded-md left-1/2 transform -translate-x-1/2 shadow-xl">
                        <img class="absolute top-0 p-4 left-0 w-full rounded-md object-cover lg:max-h-[200px]" src="../../public/photos/jat2023cardspic.jpg" />
                    </div>
                    <img class="lg:hidden absolute top-0 p-4 left-0 w-full rounded-md object-cover max-h-[200px] md:max-h-[400px]" src="../../public/photos/jat2023cardspic.jpg" />

                    <div class="relative p-4 text-center">
                        <h4 class="text-brand-dark-shade font-bold">2024 Junior Advanced Team</h4>
                        <p class="card-description text-dark-secondary text-[rgb(26,46,102)]">The JAT team competes against many other talented drill teams and with much hard work and dedication, Blue Shadows has ridden away as the California State Champions for the past eight years, Western States Drill Team Champions in 2009, 2011, 2012, 2013, 2016, 2017 and 2018.</p>
                    </div>
                </div>

                <div class="shadow-2xl rounded-lg mt-7 mx-auto w-full min-h-[300px] bg-brand-light-shade relative pt-[65%]">
                    <div class="hidden lg:block absolute top-[-30px] bg-brand-main-color w-[85%] h-[50%] p-4 rounded-md left-1/2 transform -translate-x-1/2 shadow-xl">
                        <img class="absolute top-0 p-4 left-0 w-full rounded-md object-cover" src="../../public/photos/parade-team.jpg" />
                    </div>
                    <img class="lg:hidden absolute top-0 p-4 left-0 w-full rounded-md object-cover" src="../../public/photos/parade-team.jpg" />

                    <div class="relative p-4 text-center">
                        <h4 class="text-brand-dark-shade font-bold">Parades</h4>
                        <p class="card-description text-dark-secondary text-[rgb(26,46,102)]">Blue Shadows performs in many parades such as the Old Spanish Days parade in Santa Barbara, the La Cañada Memorial Day Parade, and the Montrose Holiday Parade. Riders must have excellent troop attendance & inspection scores, participate in monthly meetings, and enjoy interacting with people!</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- how to join? -->
    <section class="bg-brand-dark-shade">
        <div class="container pt-[50px] pb-[50px] lg:pt-[100px] lg:pb-[100px]">
            <div class="bs-how-to-join">
                <div class="w-full min-h-[350px] bg-brand-light-shade rounded-xl flex flex-col align-items-center justify-center mx-auto p-4">
                    <h2 class="text-brand-dark-shade font-bold text-center mb-4">Are you interested in joining Blue Shadows?</h2>
                    <p class="text-brand-dark-shade text-center">We are a great organization for girls and boys who love horses and would love to learn how to ride or how to improve their equestrian skills. Blue Shadows is not simply horseback riding lessons, but it is a team where riders will participate with others as a group and attend weekly practices. These practices are run by experienced horsemen and women. Blue Shadows is a great organization for those seeking an interesting alternative to lessons, since we incorporate fun activities in an environment where riders will gain unique skills and meet lifelong friends.</p>
                    <div class="cta-group flex justify-center items-center pt-8 space-x-2 lg:space-x-4">
                        <button class="bg-brand-blue-400 text-brand-light-shade p-4 rounded-md flex flex-col text-center items-center md:block md:text-left" @click="goToSection('contact-section')">
                            <font-awesome-icon :icon="['far', 'envelope']" size="lg" class="text-brand-light-shade" />
                            <span class="md:pl-2 text-brand-light-shade">Contact Us</span>
                        </button>
                        <button @click="goToSection('donate-section')" class="bg-brand-light-accent text-brand-light-shade p-4 rounded-md flex flex-col text-center items-center md:block md:text-left">
                            <font-awesome-icon :icon="['fas', 'hand-holding-dollar']" size="lg" class="text-brand-light-shade" />
                            <span class="md:pl-2 text-brand-light-shade">Donate Today</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- services -->
    <section>
        <div class="container pt-[50px] pb-[50px] lg:pt-[100px] lg:pb-[100px]">
            <div class="mx-auto">
                <h2 class="text-center font-bold">Riding Class Types</h2>
                <VerticalImagesWithTabs />
            </div>
        </div>
    </section>

    <!-- testimonials -->
    <!-- <section>
        <div class="container pt-[50px] pb-[50px] lg:pt-[100px] lg:pb-[100px]">
            <TestimonialCards />
        </div>
    </section> -->

    <!-- faq -->
    <section class="bg-brand-medium-purple">
        <div class="container pt-[50px] pb-[50px] lg:pt-[100px] lg:pb-[100px]">
            <FaqSection />
        </div>
    </section>

    <!-- donate -->
    <section>
        <div class="container pt-[50px] pb-[50px] lg:pt-[100px] lg:pb-[100px]" id="donate-section">
            <h3>We thank you so much for your interest in supporting our organization. Blue Shadows Mounted Drill Team is a 501(c)(3) non-profit organization that is entirely volunteer-based. Our programs rely upon support from donations, fundraisers and sponsorships.</h3>
            <div class="grid grid-cols-1 gap-y-2 pt-2">
                <!-- facebook -->
                <a href="https://www.facebook.com/BlueShadowsMDT/" target="_blank" class="border-brand-dark-shade border-2 border-solid rounded-md bg-white p-2">
                    <div class="flex flex-col md:flex-row items-center">
                        <font-awesome-icon :icon="['fab', 'square-facebook']" size="2xl" />
                        <span class="md:pl-4 text-center md:text-left">Visit our Facebook page (and feel free to “like” it while you’re there!) and click the Donate button!</span>
                    </div>
                </a>
                <!-- ralphs -->
                <a href="https://www.ralphs.com/" target="_blank" class="border-brand-dark-shade border-2 border-solid rounded-md bg-white p-2">
                    <div class="flex flex-col md:flex-row items-center">
                        <font-awesome-icon :icon="['fas', 'sack-dollar']" size="2xl" />
                        <span class="md:pl-4 text-center md:text-left">Login in to or Sign up for your Ralphs Rewards Club account; Click on “My Account” and then select “Community Contributions” and enter our Organization number “IX213” and enroll! Use your Rewards Club card or associated phone number everytime you check out and a portion automatically comes to us!</span>
                    </div>
                </a>
                <a href="https://www.goodshop.com/" target="_blank" class="border-brand-dark-shade border-2 border-solid rounded-md bg-white p-2">
                    <div class="flex flex-col md:flex-row items-center">
                        <font-awesome-icon :icon="['fas', 'circle-dollar-to-slot']" size="2xl" />
                        <span class="md:pl-4 text-center md:text-left">Visit Goodshop and add “Blue Shadows” as your organization; be sure to access those hundreds of stores through Goodshop’s page and they will keep track of donations as your purchase !</span>
                    </div>
                </a>
                <a href="https://www.paypal.com/donate/?hosted_button_id=XAL9XU7JUM2UY" target="_blank" class="border-brand-dark-shade border-2 border-solid rounded-md bg-white p-2">
                    <div class="flex flex-col md:flex-row items-center">
                        <font-awesome-icon :icon="['fab', 'paypal']" size="2xl" />
                        <span class="md:pl-4 text-center md:text-left">Visit our Paypal Giving Fund Page (no fees taken out of the donation)! No Paypal account? No problem, you can still donate with credit or debit (with a small fee taken out of the donation) by clicking <a href="https://www.paypal.com/donate/?hosted_button_id=XAL9XU7JUM2UY" target="_blank" class="underline">here</a>!</span>
                    </div>
                </a>
                <a href="https://www.amazon.com/hz/wishlist/ls/MFI1CO9U8BU9/ref=hz_ls_biz_ex" target="_blank" class="border-brand-dark-shade border-2 border-solid rounded-md bg-white p-2">
                    <div class="flex flex-col md:flex-row items-center">
                        <font-awesome-icon :icon="['fab', 'amazon']" size="2xl" />
                        <span class="md:pl-4 text-center md:text-left">We have created a <a href="https://www.amazon.com/hz/wishlist/ls/MFI1CO9U8BU9/ref=hz_ls_biz_ex" class="underline">wishlist</a> through Amazon for some of the items that our organization is in need of. These items help support our teams as they travel for competitions, parades, and other performances. We appreciate all the support given to us over the years.</span>
                    </div>
                </a>
            </div>
        </div>
    </section>

    <!-- Contact Us -->
    <section id="contact-section">
        <div class="bg-brand-light-shade footer">
            <div class="container pb-[100px]">
                <div class="grid grid-cols-1 gap-x-12 gap-y-8 lg:grid-cols-2 flex justify-content-center align-content-center">
                    <div class="flex flex-col justify-center">
                        <h2 class="pb-8 text-center font-bold">Get in Touch With Us</h2>
                        <h5 class="text-center">Questions? Comments? Please contact us! If you would like more information about Blue Shadows or if you would like us to perform at your event, please reach out to us.</h5>
                        <div class="p-4 my-4 bg-white rounded-lg drop-shadow-xl text-center min-h-[200px] w-full flex flex-col justify-center">
                            <h5 class="pb-3">Blue Shadows is currently located in Lakeview Terrace at the Hansen Dam Horse Park.</h5>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3297.103728642551!2d-118.37028299999999!3d34.27140699999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c293b811898b55%3A0xf2b516e36de16717!2sHansen%20Dam%20Horse%20Park!5e0!3m2!1sen!2sus!4v1729228781624!5m2!1sen!2sus" width="100%" height="100%" style="border: 0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            <address class="py-3">
                                <a href="https://maps.app.goo.gl/QqUa87gEswGJ258u9" target="_blank">
                                    <p>Hansen Dam Horse Park</p>
                                    <p>11127 Orcas Ave</p>
                                    <p>Lake View Terrace, CA 91342</p>
                                </a>
                            </address>
                            <p>Janet: <a href="tel:8187313096" class="underline">(818) 731 3096</a></p>
                            <a href="mailto:contact@blueshadows.org" class="underline">Contact@blueshadows.org</a>
                        </div>
                    </div>
                    <div class="w-full rounded-xl bg-brand-light-accent drop-shadow-xl p-[25px]">
                        <ContactTab />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
