<script>
import axios from "axios";
import { Form, Field, ErrorMessage, useForm } from "vee-validate";

export default {
    name: "ContactTab",

    components: {
        Form,
        Field,
        ErrorMessage,
    },

    data() {
        return {
            thankyoutoggle: false,
            recaptchaSucceeded: false,
            submitLoading: false,
        };
    },

    mounted() {
        // re-initalize the script so that recaptcha refreshes to allow multiple form submissions
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/enterprise.js";
        script.defer = true; // async added by default with dynamic adding
        document.head.appendChild(script);

        // Make the onSuccess Vue function window global so the data-callback tag can find it.
        window.onSuccess = this.onSuccess; // make sure this is valid if user refreshes page within time limit
        window.onError = this.onError;
        window.onExpire = this.onExpire;
    },

    methods: {
        // this function will only be called once all validation checks have happened and are verified (based on your custom validation functions).
        // vee-validate does these logic checks for you so you just call the POST request here
        handleSubmission(values) {
            document.getElementById("recaptcha-form-error").style.display = "inline";
            if (this.recaptchaSucceeded == true) {
                document.getElementById("recaptcha-form-error").style.display = "none";
                this.submitForm(values);
            }
        },

        onSuccess() {
            this.recaptchaSucceeded = true;
            document.getElementById("recaptcha-form-error").style.display = "none";
        },

        onExpire() {
            this.recaptchaSucceeded = false;
            document.getElementById("recaptcha-form-error").style.display = "inline";
        },

        onError() {
            this.recaptchaSucceeded = false;
            document.getElementById("recaptcha-form-error").style.display = "inline";
        },

        validateName(value) {
            if (!value) {
                return "Please enter your name.";
            }
            if (value.length <= 0) {
                return "Please enter your name.";
            }
            return true;
        },

        validateEmail(value) {
            if (!value) {
                return "Please enter your email address.";
            }
            // if the field is not a valid email
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {
                return "Please enter a valid email address.";
            }
            return true;
        },

        validatePhone(value) {
            if (!value || value.length <= 0) return "Please enter your phone number.";
            const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;
            if (!phoneRegex.test(value)) {
                return "Please enter a valid United States phone number.";
            }

            return true;
        },

        saveMessage(value) {
            if (!value || value.length <= 0) return "Please fill out this field.";
            if (value.length > 1000) return "Message must be less than 1000 characters.";
            if (value.length < 15) return "Please enter at least 15 characters.";
            return true;
        },

        async submitForm(data) {
            try {
                // Send the form data using axios with proper headers
                this.submitLoading = true;
                const response = await axios.post("https://script.google.com/macros/s/AKfycbwmb29va5SC1rvAKR0AIkUfH1yKwKaF86eUkGFAilB_Qv3Gl7DDYep5eBSz-Fa_85pMOw/exec", data, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                });
                this.thankyoutoggle = true;
                this.submitLoading = false;
            } catch (e) {
                this.thankyoutoggle = false;
                console.error(e);
            }
        },
    },
};
</script>

<template>
    <div class="tab-container mx-auto" v-if="thankyoutoggle == false">
        <!-- form here -->
        <div class="flex flex-wrap">
            <Form class="mb-12 w-full shrink-0 grow-0 basis-auto" name="submit-to-google-sheets" @submit="handleSubmission" v-slot="{ isSubmitting }">
                <div class="mb-3 w-full">
                    <label class="block font-medium mb-[2px] text-white" htmlFor="contactFormName"> Name </label>
                    <Field name="name" :rules="validateName" v-slot="{ field, meta }">
                        <input v-bind="field" type="text" class="px-2 py-2 border w-full outline-none rounded-md name-input" id="contactFormName" placeholder="Name" :class="{ 'is-invalid border-red-950': meta.touched && meta.errors.length, 'is-valid border-green-500': meta.touched && !meta.errors.length }" />
                        <ErrorMessage name="name" class="text-red-950" />
                    </Field>
                </div>

                <div class="mb-3 w-full">
                    <label class="block font-medium mb-[2px] text-white" htmlFor="contactFormEmail"> Email </label>
                    <Field name="email" :rules="validateEmail" v-slot="{ field, meta }">
                        <input v-bind="field" type="email" class="px-2 py-2 border w-full outline-none rounded-md email-input" id="contactFormEmail" placeholder="Email address" :class="{ 'is-invalid border-red-950': meta.touched && meta.errors.length, 'is-valid border-green-500': meta.touched && !meta.errors.length }" />
                        <ErrorMessage name="email" class="text-red-950" />
                    </Field>
                </div>

                <div class="mb-3 w-full">
                    <label class="block font-medium mb-[2px] text-white" htmlFor="contactFormPhone"> Phone </label>
                    <Field name="phone" :rules="validatePhone" v-slot="{ field, meta }">
                        <input v-bind="field" type="tel" class="px-2 py-2 border w-full outline-none rounded-md email-input" id="contactFormPhone" name="phone" placeholder="Phone Number" pattern="[0-9]" :class="{ 'is-invalid border-red-950': meta.touched && meta.errors.length, 'is-valid border-green-500': meta.touched && !meta.errors.length }" />
                        <ErrorMessage name="phone" class="text-red-950" />
                    </Field>
                </div>

                <div class="mb-3 w-full">
                    <label class="block font-medium mb-[2px] text-white" htmlFor="contactFormMessage"> Message </label>
                    <Field type="textarea" name="message" :rules="saveMessage" v-slot="{ field, meta }">
                        <textarea v-bind="field" type="textarea" class="px-2 py-2 border rounded-[5px] w-full outline-none min-h-24" id="contactFormMessage" name="message" placeholder="Your Message Here" :class="{ 'is-invalid border-red-950': meta.touched && meta.errors.length, 'is-valid border-green-500': meta.touched && !meta.errors.length }" />
                        <ErrorMessage name="message" class="text-red-950" />
                    </Field>
                </div>

                <div class="mb-3 w-full">
                    <div class="g-recaptcha mt-6" data-action="LOGIN" data-sitekey="6LffZ2oqAAAAAENJooAeakEHnqwzEaGHWMJHQb1p" data-callback="onSuccess" data-expired-callback="onExpire" data-error-callback="onError"></div>
                    <span id="recaptcha-form-error" class="text-red-950" style="display: none">Please confirm you are human.</span>

                    <button type="submit" id="submitbtn" class="mt-6 w-full rounded bg-brand-main-color px-6 py-2.5 font-medium uppercase leading-normal text-white hover:shadow-md hover:bg-brand-dark-accent" :disabled="isSubmitting" v-if="!submitLoading">Send</button>

                    <div class="mb-6 w-full px-6 py-2.5 font-medium uppercase leading-normal" v-if="submitLoading">
                        <div class="loader mx-auto"></div>
                    </div>
                </div>
            </Form>
        </div>
    </div>
    <div class="tab-container mx-auto" v-else>
        <h3 class="text-white text-center">Thank you for your submission. We will be in contact with you shortly.</h3>
    </div>
</template>

<style lang="css" scoped>
.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
}
.loader::before,
.loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid black;
    animation: prixClipFix 2s linear infinite;
}
.loader::after {
    transform: rotate3d(90, 90, 0, 180deg);
    border-color: rgb(15, 130, 233);
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    75%,
    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
}
</style>
