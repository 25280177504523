<script>
import { Amplify } from "aws-amplify";
import amplifyconfig from "../amplifyconfiguration.json";
import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
Amplify.configure(amplifyconfig);
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
import { getUrl } from "@aws-amplify/storage";
import { list } from "@aws-amplify/storage";
import { Hub } from "aws-amplify/utils";

import MemberSettingsModal from "./MemberSettingsModal.vue";
import DeleteAccountModal from "./DeleteAccountModal.vue";
export default {
    name: "MembersTab",
    data() {
        return {
            fileObject: {},
            person: {},
            isAuthenticated: false,
            leadership: [
                { name: "Colonel Tammy Wickham", role: "Regimental Commander, President", url: "https://placehold.co/100" },
                { name: "Lt. Colonel Janet Johnstone", role: "Regimental Executive Officer, Vice President", url: "https://placehold.co/100" },
                { name: "Lt. Colonel Virginia Muecke", role: "Regimental / Commanding Officer, Secretary", url: "https://placehold.co/100" },
                { name: "Lt. Colonel Tracy Ramirez", role: "Lakeview Terrace Post Commander", url: "https://placehold.co/100" },
                { name: "Major Erin Briggs", role: "Regimental Officer, Treasurer", url: "https://placehold.co/100" },
                { name: "Major Michelle Ernst", role: "Lakeview Terrace Post Adjutant", url: "https://placehold.co/100" },
                { name: "Major April Penza", role: "Regimental Officer", url: "https://placehold.co/100" },
                { name: "Major April Verway", role: "Regimental Officer", url: "https://placehold.co/100" },
                { name: "Major Allyson Wreede", role: "Regimental Officer", url: "https://placehold.co/100" },

                { name: "Captain Nick Evans", role: "Commanding Officer", url: "https://placehold.co/100" },
                { name: "Captain Breanna Fone", role: "Commanding Officer", url: "https://placehold.co/100" },
                { name: "Captain Taryn Smith", role: "Commanding Officer", url: "https://placehold.co/100" },
            ],
            troopInfo: [
                { name: "C", day: "Tuesdays", url: require("../../public/photos/memberstabphotos/troopc.jpg") },
                { name: "K", day: "Wednesdays", url: require("../../public/photos/memberstabphotos/troopk.jpg") },
                { name: "R", day: "Thursdays", url: "https://placehold.co/100" },
            ],
            showModal: false,
            showDeleteModal: false,
        };
    },

    components: {
        Authenticator,
        MemberSettingsModal,
        DeleteAccountModal,
    },
    computed: {
        auth: function () {
            return useAuthenticator();
        },
    },
    async created() {
        try {
            await getCurrentUser();
            this.isAuthenticated = true; // User is signed in
            this.getName();
            this.fetchFiles();
        } catch (error) {
            this.isAuthenticated = false; // User is not signed in
        }

        Hub.listen("auth", (data) => {
            const { event } = data.payload;
            if (event === "signedIn") {
                this.isAuthenticated = true;
                this.getName();

                this.fetchFiles();
            } else if (event === "signedOut") {
                this.isAuthenticated = false;
                this.fileObject = {};
                this.person = {};
            }
        });
    },

    methods: {
        signOut() {
            this.fileObject = {};
            this.person = {};
        },
        async fetchFiles() {
            try {
                const result = await list({
                    path: "documents/",
                });
                const filePathUrls = result.items.filter((item) => item.size > 0).map((item) => item.path); // get the urls (unsigned)
                const fileNamesExtracted = filePathUrls.map((path) => path.replace("documents/", "")); // get the names of files using unsigned urls

                // get signed urls
                const signedUrls = await Promise.all(fileNamesExtracted.map((fileName) => getUrl({ path: `documents/${fileName}` })));

                // store files as objects in order to list and download them in html
                const filesObj = fileNamesExtracted.map((name, index) => ({
                    name: name,
                    url: signedUrls[index],
                }));

                // update vue variable
                this.fileObject = filesObj;
            } catch (e) {
                console.error("Cannot get files: " + e);
            }
        },
        async getName() {
            try {
                const user = await fetchUserAttributes();
                // console.log("Fetched User:", user); // Log the fetched user to inspect attributes
                this.person = user;
            } catch (error) {
                console.error("Error fetching user:", error);
            }
        },
    },
};
</script>

<template>
    <section>
        <div class="authenticatorWrap container pt-[50px] pb-[50px] lg:pt-[100px] lg:pb-[100px]">
            <authenticator :sign-up-attributes="['email', 'name', 'phone_number']">
                <template v-slot:sign-in-header>
                    <h3 class="text-center">Welcome back members! Please sign in.</h3>
                </template>
                <template v-slot:sign-up-header>
                    <h3 class="text-center">Please create a Blue Shadows member account to access important information.</h3>
                </template>

                <template v-slot="{ user, signOut }">
                    <div class="grid grid-cols-2 py-4">
                        <div v-show="user" class="signedInContainer col-span-1 flex grow">
                            <h3 class="hidden md:block">Welcome back, {{ person.name }}!</h3>
                            <h5 class="md:hidden">Welcome back, {{ person.name }}!</h5>
                        </div>
                        <!-- account settings -->
                        <div class="flex flex-col items-end">
                            <button @click="signOut" class="text-brand-dark-shade">
                                <p>Log Out <font-awesome-icon :icon="['fas', 'right-from-bracket']" class="pl-2" /></p>
                            </button>
                            <button @click="showModal = true" class="text-brand-dark-shade">
                                <p>Settings <font-awesome-icon :icon="['fas', 'gear']" class="pl-2" /></p>
                            </button>
                            <member-settings-modal
                                v-if="showModal"
                                @close="
                                    showModal = false;
                                    getName();
                                "
                                :person="person"
                            />
                        </div>
                    </div>

                    <h3 class="text-center py-4">Upcoming Events</h3>
                    <div class="text-center justify-center items-center flex">
                        <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FLos_Angeles&showPrint=0&src=N3FndGpxbHU1M2w1ZXU1NWU4MmszZ3FndTRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%230083c6" style="border: solid 1px #777" width="100%" height="300" frameborder="0" scrolling="no"></iframe>
                    </div>
                    <!--  -->
                    <h3 class="text-center py-4">Regimental and Commanding Officers</h3>
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                        <div v-for="(person, index) in leadership" class="bg-brand-dark-accent p-4 rounded-xl" :key="index">
                            <div class="text-center flex flex-col justify-center">
                                <img :src="person.url" class="rounded-lg h-[50px] md:h-[75px] lg:h-[100px]" />
                                <h4 class="font-bold pt-2">{{ person.name }}</h4>
                                <p class="italic">{{ person.role }}</p>
                            </div>
                        </div>
                    </div>

                    <h3 class="text-center py-4">Troops</h3>
                    <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
                        <div v-for="(troop, index) in troopInfo" class="" :key="index">
                            <div class="text-center flex flex-col justify-center">
                                <img :src="troop.url" class="rounded-lg h-[50px] md:h-[75px] lg:h-[100px] object-contain" />
                                <h4 class="font-bold pt-2">{{ troop.name }}</h4>
                                <p class="italic">{{ troop.day }}</p>
                            </div>
                        </div>
                    </div>

                    <h3 class="text-center py-4">Downloads</h3>
                    <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-x-2 gap-y-2">
                        <div v-for="(file, index) in fileObject" :key="index" class="">
                            <a :href="file.url.url" target="_blank" class="block rounded-md border-dashed border-2 border-brand-light-accent min-h-[200px] text-center relative p-3">
                                <div class="absolute inset-0 flex flex-col items-center justify-center p-1 md:p-4">
                                    <font-awesome-icon :icon="['fas', 'file-pdf']" size="2xl" />
                                    <p class="text-center break-words whitespace-normal overflow-wrap-anywhere max-w-full">{{ file.name }}</p>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="flex mt-12">
                        <button class="rounded text-red-500 border-solid border-2 border-red-500 px-2 py-2 font-small" @click="showDeleteModal = true"><font-awesome-icon :icon="['fas', 'trash-can']" /><span class="pl-1">Delete My Account</span></button>
                        <delete-account-modal v-if="showDeleteModal" @closeEvent="showDeleteModal = false" />
                    </div>
                </template>

                <template v-slot:footer>
                    <div class="flex content-center justify-center text-center pt-2">
                        <p>Powered by <font-awesome-icon :icon="['fab', 'aws']" size="xl" /></p>
                    </div>
                </template>
            </authenticator>
        </div>
    </section>
</template>

<style>
.amplify-button {
    background: rgb(15, 130, 233);
}
.amplify-button:hover {
    background: rgb(0, 72, 173);
}
.amplify-button:active,
.amplify-button:focus {
    border-color: rgb(0, 191, 255);
    box-shadow: none;
}
.amplify-button--link {
    background: rgb(15, 130, 233);
    color: white;
}
.amplify-tabs__item--active {
    color: rgb(15, 130, 233);
    border-color: black;
}
.amplify-tabs__item--active:hover,
.amplify-tabs__item:hover {
    color: rgb(0, 72, 173);
}
[data-amplify-authenticator] [data-amplify-router] {
    border-color: rgb(15, 130, 233);
    border-radius: 6px;
    box-shadow: none;
    background-color: transparent;
}
:root {
    --amplify-fonts-default-variable: "Montserrat";
}
</style>
