<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);
export default {
    name: "NavBar",

    data() {
        return {
            toggleMenu: false,
        };
    },

    methods: {
        isActive(link) {
            // Check for routes with hashes

            if (link.startsWith("#")) {
                return this.$route.path === "/" && this.$route.hash === link;
            }
            // Check for routes without hashes
            return this.$route.path === link;
        },
    },
};
</script>

<template>
    <div class="bg-brand-blue-500 sticky top-0 z-50">
        <div class="container w-full">
            <!-- mobile -->
            <button class="lg:hidden flex justify-between items-center min-h-[40px] w-full" @click="toggleMenu = !toggleMenu">
                <span class="text-white self-center">Blue Shadows Mounted Drill Team</span>
                <div class="text-white uppercase self-center">
                    <font-awesome-icon v-if="!toggleMenu" :icon="['fas', 'bars']" class="fa-fw" />
                    <font-awesome-icon v-else :icon="['fas', 'xmark']" class="fa-fw" />
                </div>
            </button>
            <div v-show="toggleMenu" class="lg:hidden border-t-solid border-t-[1px] border-white">
                <router-link :to="{ path: '/', hash: '#home-section' }" :class="{ active: isActive('#home-section') }" class="flex text-center items-center py-[5px] px-0 uppercase" @click="this.toggleMenu = false">Home</router-link>
                <router-link :to="{ path: '/', hash: '#about-section' }" :class="{ active: isActive('#about-section') }" class="flex text-center items-center py-[5px] px-0 uppercase" @click="this.toggleMenu = false">About Us</router-link>
                <router-link exact :to="{ path: '/', hash: '#contact-section' }" :class="{ active: isActive('#contact-section') }" class="flex text-center items-center py-[5px] px-0 uppercase" @click="this.toggleMenu = false">Get in Touch</router-link>
                <router-link :to="{ name: 'Members' }" :class="{ active: isActive('/members/') }" class="flex text-center items-center py-[5px] px-0 uppercase" @click="this.toggleMenu = false">Members Portal</router-link>
            </div>
        </div>

        <!-- desktop -->
        <div class="hidden lg:block px-[50px]">
            <div class="flex min-h-[40px] justify-between align-content-center">
                <div class="flex items-center">
                    <img src="../../public/photos/BSLogoBlackTrans.png" alt="Blue Shadows Icon" class="mx-auto max-h-[32px] lg:my-2 object-center block lg:inline" />
                    <router-link :to="{ path: '/' }" class="text-white text-center lg:text-left lg:pl-1 inline">Blue Shadows Mounted Drill Team</router-link>
                </div>

                <div class="flex space-x-8 font-bold items-center justify-center text-white text-center">
                    <router-link :to="{ path: '/', hash: '#about-section' }" class="">About Us</router-link>
                    <router-link :to="{ path: '/', hash: '#contact-section' }" class="">Get in Touch</router-link>
                    <router-link :to="{ name: 'Members' }" class="">Members Portal</router-link>
                </div>

                <div class="flex items-center text-center">
                    <a href="https://www.facebook.com/BlueShadowsMDT/" target="_blank" class="px-2">
                        <font-awesome-icon :icon="['fab', 'facebook']" size="xl" inverse />
                    </a>

                    <a href="https://www.youtube.com/results?search_query=blue+shadows+mounted+drill+team" target="_blank" class="px-2">
                        <font-awesome-icon :icon="['fab', 'youtube']" size="xl" inverse />
                    </a>
                    <a href="https://www.threads.net/@blueshadowsmounteddrillteam" target="_blank" class="px-2">
                        <font-awesome-icon :icon="['fab', 'threads']" size="xl" inverse />
                    </a>
                    <a href="https://www.instagram.com/blueshadowsmounteddrillteam/" target="_blank" class="px-2">
                        <font-awesome-icon :icon="['fab', 'instagram']" size="xl" inverse />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.active {
    color: white;
}
</style>
