<script>
import { Amplify } from "aws-amplify";
import amplifyconfig from "../amplifyconfiguration.json";
import { confirmUserAttribute, fetchUserAttributes, getCurrentUser, updateUserAttributes } from "aws-amplify/auth";

Amplify.configure(amplifyconfig);
import "@aws-amplify/ui-vue/styles.css";
import { Form, Field, ErrorMessage, useForm } from "vee-validate";
import { ref } from "vue";

export default {
    name: "MemberSettingsModal",
    emits: ["close"],
    data() {
        return {
            newPersonInfo: { name: "", email: "", phone_number: "" },
            submitLoading: false,
            updatedToggle: false,
            showCodeField: false,
            verifyEmail: false,
            errorMessage: "",
            displayErrorOnSubmission: false,
        };
    },
    props: {
        person: {},
    },
    setup(_, { emit }) {
        const handleClose = () => {
            emit("close"); // Emit the 'close' event
        };
        const verificationCode = ref("");

        return { handleClose, verificationCode };
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },

    created() {
        this.userInfo = this.getUserInfo();
    },

    methods: {
        validateName(value) {
            if (value && value.length > 0) return true;
        },

        validateEmail(value) {
            if (value) {
                // if the field is not a valid email
                const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
                if (!regex.test(value)) {
                    return "Please enter a valid email address.";
                }
            }

            return true;
        },

        validatePhone(value) {
            if (!value || value.length <= 0) return "Please enter your phone number.";
            const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;
            if (!phoneRegex.test(value)) {
                return "Please enter a valid United States phone number.";
            }

            return true;
        },
        async getUserInfo() {
            try {
                const user = await fetchUserAttributes();

                return user;
            } catch (error) {
                console.error("Error getting User", error);
            }
        },

        async saveUserInfo() {
            try {
                this.submitLoading = true; // turn on spinner
                await updateUserAttributes({
                    userAttributes: {
                        email: this.newPersonInfo.email,
                        name: this.newPersonInfo.name,
                        phone_number: this.newPersonInfo.phone_number,
                    },
                });
                if (this.newPersonInfo.email !== this.person.email) {
                    this.showCodeField = true;
                    // call async on email verification
                } else {
                    this.submitLoading = false; // turn off spinner
                    this.updatedToggle = true; // finish submission
                }
                this.displayErrorOnSubmission = false;
                this.errorMessage = "";
            } catch (e) {
                this.submitLoading = false;
                this.displayErrorOnSubmission = true;
                this.errorMessage = e;
            }
        },
        async verifyCode() {
            try {
                await confirmUserAttribute({
                    userAttributeKey: "email",
                    confirmationCode: this.verificationCode,
                });

                this.updatedToggle = true; // finish submission
                this.displayErrorOnSubmission = false;
                this.errorMessage = "";
            } catch (e) {
                this.displayErrorOnSubmission = true;
                this.errorMessage = e;
            }
            this.showCodeField = false; // hide code field
            this.submitLoading = false; // turn off when done
        },

        handleUpdates(values) {
            this.newPersonInfo = values; // save values to object for post request
            this.saveUserInfo(); // inital POST request
        },
    },
};
</script>

<template>
    <div class="modal fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
        <div class="modal-content bg-white rounded-lg shadow-lg w-[90%] md:w-1/3">
            <div class="flex justify-end py-1 px-2">
                <button class="" @click="handleClose"><font-awesome-icon :icon="['fas', 'xmark']" size="xl" /></button>
            </div>
            <div class="px-6 pb-3">
                <div v-if="updatedToggle == false">
                    <h2 class="text-center pb-2">Update Your Profile Information</h2>

                    <div class="flex flex-wrap" v-show="userInfo">
                        <Form class="mb-12 w-full shrink-0 grow-0 basis-auto" @submit="handleUpdates" v-slot="{ isSubmitting }" :initialValues="person">
                            <div class="mb-1 w-full space-y-2">
                                <!-- name -->
                                <div class="flex justify-between">
                                    <h5 class="text-bold">Name:</h5>
                                </div>
                                <Field name="name" :rules="validateName" v-slot="{ field, meta }">
                                    <input v-bind="field" type="text" class="px-2 py-2 border w-full outline-none rounded-md name-input" id="updateAccountFormName" :class="{ 'is-invalid border-red-500': meta.touched && meta.errors.length, 'is-valid border-green-500': meta.touched && !meta.errors.length }" />
                                    <ErrorMessage name="name" class="text-red-950" />
                                </Field>
                                <!-- phone -->
                                <div class="flex justify-between">
                                    <h5 class="text-bold">Phone:</h5>
                                </div>
                                <Field name="phone_number" :rules="validatePhone" v-slot="{ field, meta }">
                                    <input v-bind="field" type="tel" class="px-2 py-2 border w-full outline-none rounded-md email-input" id="updateAccountFormPhone" name="phone" placeholder="Phone Number" pattern="[0-9]" :class="{ 'is-invalid border-red-500': meta.touched && meta.errors.length, 'is-valid border-green-500': meta.touched && !meta.errors.length }" />
                                    <ErrorMessage name="phone" class="text-red-950" />
                                </Field>
                                <div class="flex justify-between">
                                    <h5 class="text-bold">Email:</h5>
                                </div>
                                <Field name="email" :rules="validateEmail" v-slot="{ field, meta }">
                                    <input v-bind="field" type="email" class="px-2 py-2 border w-full outline-none rounded-md email-input" id="contactFormEmail" placeholder="Email address" :class="{ 'is-invalid border-red-500': meta.touched && meta.errors.length, 'is-valid border-green-500': meta.touched && !meta.errors.length }" />
                                    <ErrorMessage name="email" class="text-red-950" />
                                </Field>

                                <button type="submit" id="submitbtnAccountInfo" class="mt-6 w-full rounded bg-brand-main-color px-6 py-2.5 font-medium uppercase leading-normal text-white hover:shadow-md hover:bg-brand-dark-accent" :disabled="isSubmitting" v-if="!submitLoading">Save Changes</button>

                                <div class="mb-6 w-full px-6 py-2.5 font-medium uppercase leading-normal" v-if="submitLoading">
                                    <div class="loader mx-auto"></div>
                                </div>
                            </div>
                        </Form>

                        <!-- only show this if email needs to be updated -->
                        <div v-if="showCodeField">
                            <label for="verificationCode">You must enter the verification code to confirm your email:</label>
                            <input type="text" v-model="verificationCode" id="verificationCode" required class="px-2 py-2 border w-full outline-none rounded-md email-input" />
                            <button class="mt-6 w-full rounded bg-brand-main-color px-6 py-2.5 font-medium uppercase leading-normal text-white hover:shadow-md hover:bg-brand-dark-accent" @click="verifyCode">Confirm</button>
                        </div>
                        <p class="italic">**To change your password, please sign out and select "forgot password", then follow the reset instructions.</p>
                        <p v-if="displayErrorOnSubmission" class="text-red-950">{{ errorMessage }}</p>
                    </div>
                </div>
                <div v-else>Your changes have been saved.</div>
            </div>
        </div>
    </div>
</template>

<style lang="css" scoped>
.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
}
.loader::before,
.loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid black;
    animation: prixClipFix 2s linear infinite;
}
.loader::after {
    transform: rotate3d(90, 90, 0, 180deg);
    border-color: rgb(15, 130, 233);
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    75%,
    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
}
</style>
