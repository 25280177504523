<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

export default {
    name: "PhotosTab",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Pagination],
        };
    },
    data() {
        return {
            items: [
            { src: require("../../public/photos/gridphotos/gridSeven.jpg") }, 
            { src: require("../../public/photos/gridphotos/gridFive.jpg") }, 
            { src: require("../../public/photos/gridphotos/gridOne.jpg") }, 
            { src: require("../../public/photos/gridphotos/gridTwo.jpg") }, 
            { src: require("../../public/photos/gridphotos/gridphotohugging.jpg") }, 
            { src: require("../../public/photos/gridphotos/blueshadowshopepic.jpg") }],
        };
    },
    mounted() {},
};
</script>

<template>
    <div class="bs-image-grid lg:justify-content-center">
        <h2 class="lg:max-w-[70%] pb-2">Since 1957, Blue Shadows has been introducing the horse world to many children who otherwise may never have that opportunity.</h2>
        <p class="lg:max-w-[70%] py-2">Blue Shadows has worked hard to instill the qualities of horsemanship, teamwork and dedication that has kept the organization running strong for over 60 years. This organization is based off of early cavalry drilling (group of horses and riders moving in various formations and units) so not only do members learn how to become better riders, but they will learn about drill maneuvers and leadership skills as well.</p>
        <div class="hidden lg:grid cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1 md:gap-3 lg:gap-4 content-center">
            <div v-for="(image, index) in items" :key="index">
                <img :src="image.src" class="rounded-lg object-cover w-full h-[400px]" :alt="'Image ' + (index + 1)" />
            </div>
        </div>
    </div>
    <div class="block lg:hidden">
        <swiper :modules="modules" :slides-per-view="'auto'" :space-between="15" :pagination="{ clickable: true }" class="w-full h-[450px]" :loop="true">
            <swiper-slide v-for="(item, index) in items" :key="index" class="p-4">
                <img :src="item.src" class="rounded-lg object-cover w-full h-full" :alt="'Image ' + (index + 1)" />
            </swiper-slide>
        </swiper>
    </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.swiper-slide {
    width: 90%;
    height: 400px;
}

.swiper-pagination-bullet {
    background: #b47288;
    opacity: 0.5;
}
.swiper-pagination-bullet-active {
    background: #0f82e9;
    opacity: 1;
}
</style>
