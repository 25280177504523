<script>
export default {
    name: "FooterBar",
};
</script>

<template>
    <section>
        <div class="bg-brand-dark-shade text-white footer">
            <div class="xl:px-[50px] xl:py-[25px]">
                <!-- desktop -->
                <div class="hidden grid lg:flex grid-cols-3 gap-x-2 text-center content-center lg:text-left justify-center lg:justify-between">
                    <div class="flex items-center">
                        <a href="https://www.facebook.com/BlueShadowsMDT/" target="_blank" class="px-2">
                            <font-awesome-icon :icon="['fab', 'facebook']" size="lg" inverse />
                        </a>
                        <a href="https://www.youtube.com/results?search_query=blue+shadows+mounted+drill+team" target="_blank" class="px-2">
                            <font-awesome-icon :icon="['fab', 'youtube']" size="lg" inverse />
                        </a>
                        <a href="https://www.threads.net/@blueshadowsmounteddrillteam" target="_blank" class="px-2">
                            <font-awesome-icon :icon="['fab', 'threads']" size="lg" inverse />
                        </a>
                        <a href="https://www.instagram.com/blueshadowsmounteddrillteam/" target="_blank" class="px-2">
                            <font-awesome-icon :icon="['fab', 'instagram']" size="lg" inverse />
                        </a>
                    </div>
                    <p class="text-center items-center flex-grow">Blue Shadows Mounted Drill Team is a registered 501(c)(3) nonprofit organization.</p>
                    <div class="hidden lg:flex items-center content-center">
                        <p class="block text-center">
                            <font-awesome-icon :icon="['far', 'copyright']" />
                            2024 | Website designed and developed by <a class="underline" href="https://www.linkedin.com/in/hannaalanizi/" target="_blank">Hanna Alanizi</a>. All rights reserved.
                        </p>
                    </div>
                </div>
                <!-- mobile -->
                <div class="flex lg:hidden grid grid-cols-1 items-center gap-y-2 pt-4">
                    <div class="text-center">
                        <a href="https://www.facebook.com/BlueShadowsMDT/" target="_blank" class="px-2">
                            <font-awesome-icon :icon="['fab', 'facebook']" size="lg" inverse />
                        </a>
                        <a href="https://www.youtube.com/results?search_query=blue+shadows+mounted+drill+team" target="_blank" class="px-2">
                            <font-awesome-icon :icon="['fab', 'youtube']" size="lg" inverse />
                        </a>
                        <a href="https://www.threads.net/@blueshadowsmounteddrillteam" target="_blank" class="px-2">
                            <font-awesome-icon :icon="['fab', 'threads']" size="lg" inverse />
                        </a>
                        <a href="https://www.instagram.com/blueshadowsmounteddrillteam/" target="_blank" class="px-2">
                            <font-awesome-icon :icon="['fab', 'instagram']" size="lg" inverse />
                        </a>
                    </div>

                    <p class="text-center items-center flex-grow">Blue Shadows Mounted Drill Team is a registered 501(c)(3) nonprofit organization.</p>

                    <p class="block text-center">
                        <font-awesome-icon :icon="['far', 'copyright']" />
                        2024
                    </p>
                    <p class="block text-center">Website designed and developed by <a class="underline" href="https://www.linkedin.com/in/hannaalanizi/" target="_blank">Hanna Alanizi</a>. All rights reserved.</p>
                </div>
            </div>
        </div>
    </section>
</template>
