<script>
export default {
    name: "FaqSection",
    data() {
        return {
            qa: [
                { question: "What is the age that one can join?", answer: "Blue Shadows is open to all kids aged 8 - 16!", isVisible: false },
                { question: "Do I have to own a horse to ride?", answer: "Our facility has horses available to rent, so ownership is not necessary. In fact, most members do not own their own horses.", isVisible: false },
                { question: "How can I get involved?", answer: "Thank you for your interest! Blue Shadows is always seeking volunteers. Please send us a message using the contact form so that we can get in touch with you.", isVisible: false },
                { question: "How does Blue Shadows use donations?", answer: "Blue Shadows is a 501(c)(3) non-profit organization. All donations directly support the program including but not limited to troop funds, equipment, and competition fees.", isVisible: false },
            ],
        };
    },
    methods: {
        expand(el) {
            el.style.height = "0px"; // Set initial height to 0
            el.offsetHeight; // Trigger reflow to ensure the height transition starts
            el.style.transition = "height 0.2s ease";
            el.style.height = el.scrollHeight + "px"; // Expand to full height
        },
        collapse(el) {
            el.style.height = el.scrollHeight + "px"; // Set height to full content height
            el.offsetHeight; // Trigger reflow to start transition
            el.style.transition = "height 0.2s ease";
            el.style.height = "0px"; // Collapse to height 0
        },
    },
};
</script>

<template>
    <section>
        <h2 class="font-bold text-center pb-4 text-white">Frequently Asked Questions</h2>

        <div class="rounded-md bg-white drop-shadow-lg my-4 md:w-[70%] mx-auto" v-for="(text, index) in qa" :key="index">
            <button class="w-full p-2" @click="text.isVisible = !text.isVisible">
                <div class="flex justify-between items-center">
                    <h5 class="font-bold text-left">{{ text.question }}</h5>

                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-if="!text.isVisible" class="text-brand-dark-shade" />
                    <font-awesome-icon :icon="['fas', 'chevron-up']" v-else class="text-brand-light-accent" />
                </div>
                <transition name="faqdropdown" @enter="expand" @leave="collapse">
                    <div :class="['text-left', 'pt-1']" v-show="text.isVisible">
                        <p class="text-left">{{ text.answer }}</p>
                    </div>
                </transition>
            </button>
        </div>
    </section>
</template>

<style>
.faqdropdown-enter-active,
.faqdropdown-leave-active {
    overflow: hidden; /* Ensure no content overflows during transition */
}
</style>
